import React from "react"
import Container from "../components/Container/Container"
import Flex from "../components/Flex/Flex"
import SEO from "../components/SEO/SEO"
import Text from "../components/Text/Text"
import { WrapperStyles } from "../components/Wrapper/Wrapper"
import ButtonLink from "../components/ButtonLink/ButtonLink"
import { motion } from "framer-motion"
import styled from "styled-components"

const svgVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.3,
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
}

const SvgStyles = styled(motion.svg)`
  margin-left: -60px;
  width: calc(100% + 60px);
  @media only screen and (max-width: 1182px) {
    margin-left: -180px;
    width: calc(100% + 180px);
  }
  @media only screen and (max-width: 740px) {
    margin: 60px -100px 0;
    width: calc(100% + 200px);
  }
  @media only screen and (max-width: 540px) {
    margin: 60px -190px 0;
    width: 140vw;
  }
  @media only screen and (max-width: 414px) {
    margin-top: 20px;
  }
`
const Icon = () => (
  <SvgStyles
    width="676"
    height="374"
    viewBox="0 0 676 374"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.g
      variants={svgVariants}
      initial="initial"
      animate="animate"
      clip-path="url(#clip0)"
    >
      <motion.path
        id="test"
        d="M578.43 323.39C579.48 324 581.19 324 582.24 323.39C583.29 322.78 583.29 321.8 582.24 321.19C581.19 320.58 579.48 320.58 578.43 321.19C577.38 321.79 577.38 322.78 578.43 323.39Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M501.701 318.43C502.751 319.04 504.461 319.04 505.511 318.43C506.561 317.82 506.561 316.84 505.511 316.23C504.461 315.62 502.751 315.62 501.701 316.23C500.641 316.84 500.641 317.82 501.701 318.43Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M467.381 337.32C468.431 337.93 470.141 337.93 471.191 337.32C472.241 336.71 472.241 335.73 471.191 335.12C470.141 334.51 468.431 334.51 467.381 335.12C466.321 335.73 466.321 336.71 467.381 337.32Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M621.631 257.23C622.681 257.84 624.391 257.84 625.441 257.23C626.491 256.62 626.491 255.64 625.441 255.03C624.391 254.42 622.681 254.42 621.631 255.03C620.571 255.64 620.571 256.62 621.631 257.23Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M366.39 230.42L366.24 230.33L294.5 188.91L294.95 188.65L366.38 147.41L366.53 147.5L438.27 188.91L437.82 189.17L366.39 230.42ZM295.71 188.91L366.39 229.72L437.07 188.91L366.39 148.1L295.71 188.91Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M392.87 163.22L392.57 162.7L441.93 134.2V87.4601L442.08 87.3701L584.42 5.19006L584.72 5.71006L442.53 87.8001V134.55L442.38 134.64L392.87 163.22Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M456.901 79.34H456.291L456.301 36.19L456.451 36.1L517.171 1.04004L517.471 1.56004L456.901 36.54V79.34Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M402.181 168.6L401.881 168.07L450.151 140.2H534.111L607.701 97.7101H620.621L620.691 97.7601L672.741 127.81L672.441 128.33L620.461 98.3201H607.871L534.271 140.81H450.321L402.181 168.6Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M330.6 168.6L233.59 112.59H175.75L175.68 112.55L139.16 91.47V57.25H139.76V91.12L175.92 111.99H233.76L233.83 112.03L330.9 168.08L330.6 168.6Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M342.211 161.89L243.681 105.01V71.2201L208.451 50.8801L208.751 50.3601L244.281 70.8701V104.66L342.511 161.37L342.211 161.89Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M354.37 154.87L296.32 121.36L296.33 92.3601L296.48 92.2701L397.03 34.2201L397.33 34.7401L296.93 92.7101V121.01L354.67 154.35L354.37 154.87Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M41.98 283.71H41.9L41.83 283.67L2.54004 260.99L2.84004 260.47L42.06 283.11H130.92L195.12 246.04H248.23L321.29 203.85L321.59 204.38L248.39 246.64H195.29L131.08 283.71H41.98Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M151.391 257.91L151.091 257.39L182.931 239H243.871L313.021 199.08L313.321 199.6L244.031 239.6H183.091L151.391 257.91Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M281.351 217.89L230.601 188.59H194.411L170.981 202.12L170.681 201.59L194.251 187.98H230.761L230.831 188.02L281.651 217.36L281.351 217.89Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M143.441 291.02L143.141 290.5L171.401 274.18V260.16H172.001V274.53L171.851 274.62L143.441 291.02Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M345.03 78.23H321.93V77.62H344.86L386.01 53.87L386.31 54.39L345.03 78.23Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M343.771 148.23H343.171V133.32L314.611 116.83V100.73H315.211V116.48L343.781 132.98L343.771 148.23Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M503.45 317.59L453.68 288.85V242.11L399.76 210.98L400.06 210.46L454.28 241.76V288.51L503.75 317.07L503.45 317.59Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M469.931 336.94L450.191 325.55V304.94L431.041 293.88V239.1L391.051 216L391.351 215.48L431.651 238.75V293.53L450.801 304.59V325.2L470.231 336.42L469.931 336.94Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M623.38 256.39L607.83 247.41H574.65L574.58 247.37L514.84 212.88H462.26L462.19 212.84L429.35 193.89L429.66 193.37L462.42 212.28H515L515.07 212.32L574.81 246.81H607.99L608.06 246.85L623.68 255.87L623.38 256.39Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M589.48 232.74L568.65 220.72H528.49V220.12H568.81L568.88 220.16L589.78 232.22L589.48 232.74Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M580.18 322.55L503.11 278.05V254.79L413.49 203.05L413.79 202.53L503.71 254.44V277.7L580.48 322.03L580.18 322.55Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M411.81 174.15L411.5 173.63L446.29 153.55H543.57L613.8 113L614.1 113.52L543.73 154.15H446.45L411.81 174.15Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M490.55 165.64H490.47L490.4 165.6L470.5 154.11L470.8 153.59L490.63 165.03H544.17V165.64H490.55Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M346.571 371.62H345.971V338.98L268.461 294.23V258.23L268.611 258.14L341.961 215.79L342.261 216.31L269.071 258.57L269.061 293.88L346.571 338.63V371.62Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M361.76 329.78L335.06 314.37H303.52V313.76H335.23L335.29 313.8L362.06 329.26L361.76 329.78Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M352.368 221.818L298.484 252.886L298.784 253.406L352.668 222.337L352.368 221.818Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M410.75 331.53H410.15V240.35L379.51 222.66L379.81 222.14L410.75 240V331.53Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M394.86 318.34H394.26V286.39L394.41 286.31L410.3 277.13L410.6 277.65L394.86 286.74V318.34Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M610.141 203.72L561.861 175.84L487.281 175.85L487.211 175.8L462.551 161.57H450.691L420.351 179.08L420.051 178.56L450.531 160.96H462.711L462.781 161L487.441 175.24H562.021L562.091 175.28L610.441 203.2L610.141 203.72Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M300.291 264.17H280.691V263.56H300.131L362.421 227.6L362.721 228.13L300.291 264.17Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M333.501 279.11L333.201 278.59L384.141 249.18V237.27L369.181 228.63L369.481 228.11L384.741 236.93V249.53L384.591 249.61L333.501 279.11Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M330.13 269.94L329.83 269.42L352.69 256.21H371.9V256.82H352.86L330.13 269.94Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M330.53 298.13L330.23 297.61L353.7 284.06V273.18L353.85 273.09L380.4 257.76V251.51H381V258.11L380.85 258.2L354.3 273.53V284.41L354.15 284.5L330.53 298.13Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M328.071 270.78C329.121 271.39 330.831 271.39 331.881 270.78C332.931 270.17 332.931 269.19 331.881 268.58C330.831 267.97 329.121 267.97 328.071 268.58C327.021 269.18 327.021 270.17 328.071 270.78Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M331.381 279.91C332.431 280.52 334.141 280.52 335.191 279.91C336.241 279.3 336.241 278.32 335.191 277.71C334.141 277.1 332.431 277.1 331.381 277.71C330.331 278.32 330.331 279.3 331.381 279.91Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M385.901 274.63C386.951 275.24 388.661 275.24 389.711 274.63C390.761 274.02 390.761 273.04 389.711 272.43C388.661 271.82 386.951 271.82 385.901 272.43C384.851 273.04 384.851 274.02 385.901 274.63Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M500.231 192.43C501.281 193.04 502.991 193.04 504.041 192.43C505.091 191.82 505.091 190.84 504.041 190.23C502.991 189.62 501.281 189.62 500.231 190.23C499.181 190.84 499.181 191.82 500.231 192.43Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M573.961 208.88C575.011 209.49 576.721 209.49 577.771 208.88C578.821 208.27 578.821 207.29 577.771 206.68C576.721 206.07 575.011 206.07 573.961 206.68C572.911 207.29 572.911 208.27 573.961 208.88Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M476.871 181.14C477.921 181.75 479.631 181.75 480.681 181.14C481.731 180.53 481.731 179.55 480.681 178.94C479.631 178.33 477.921 178.33 476.871 178.94C475.811 179.55 475.811 180.53 476.871 181.14Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M468.541 199.41C469.591 200.02 471.301 200.02 472.351 199.41C473.401 198.8 473.401 197.82 472.351 197.21C471.301 196.6 469.591 196.6 468.541 197.21C467.481 197.82 467.481 198.81 468.541 199.41Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M344.371 372.72C345.421 373.33 347.131 373.33 348.181 372.72C349.231 372.11 349.231 371.13 348.181 370.52C347.131 369.91 345.421 369.91 344.371 370.52C343.311 371.12 343.311 372.11 344.371 372.72Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M360.01 330.62C361.06 331.23 362.77 331.23 363.82 330.62C364.87 330.01 364.87 329.03 363.82 328.42C362.77 327.81 361.06 327.81 360.01 328.42C358.96 329.03 358.96 330.01 360.01 330.62Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M105.97 368.92C107.02 369.53 108.73 369.53 109.78 368.92C110.83 368.31 110.83 367.33 109.78 366.72C108.73 366.11 107.02 366.11 105.97 366.72C104.92 367.33 104.92 368.31 105.97 368.92Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M199.03 370.88C200.08 371.49 201.79 371.49 202.84 370.88C203.89 370.27 203.89 369.29 202.84 368.68C201.79 368.07 200.08 368.07 199.03 368.68C197.98 369.29 197.98 370.27 199.03 370.88Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M226.92 307.44C227.97 308.05 229.68 308.05 230.73 307.44C231.78 306.83 231.78 305.85 230.73 305.24C229.68 304.63 227.97 304.63 226.92 305.24C225.87 305.85 225.87 306.83 226.92 307.44Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M542.27 166.43C543.32 167.04 545.03 167.04 546.08 166.43C547.13 165.82 547.13 164.84 546.08 164.23C545.03 163.62 543.32 163.62 542.27 164.23C541.22 164.84 541.22 165.83 542.27 166.43Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M612.041 114.36C613.091 114.97 614.801 114.97 615.851 114.36C616.901 113.75 616.901 112.77 615.851 112.16C614.801 111.55 613.091 111.55 612.041 112.16C610.991 112.77 610.991 113.75 612.041 114.36Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M670.69 129.17C671.74 129.78 673.45 129.78 674.5 129.17C675.55 128.56 675.55 127.58 674.5 126.97C673.45 126.36 671.74 126.36 670.69 126.97C669.64 127.58 669.64 128.56 670.69 129.17Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M515.85 2.65005C516.9 3.26005 518.61 3.26005 519.66 2.65005C520.71 2.04005 520.71 1.06005 519.66 0.450054C518.61 -0.159946 516.9 -0.159946 515.85 0.450054C514.8 1.06005 514.8 2.05005 515.85 2.65005Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M328.481 298.97C329.531 299.58 331.241 299.58 332.291 298.97C333.341 298.36 333.341 297.38 332.291 296.77C331.241 296.16 329.531 296.16 328.481 296.77C327.431 297.38 327.431 298.36 328.481 298.97Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M392.66 319.44C393.71 320.05 395.42 320.05 396.47 319.44C397.52 318.83 397.52 317.85 396.47 317.24C395.42 316.63 393.71 316.63 392.66 317.24C391.61 317.84 391.6 318.83 392.66 319.44Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M408.551 333.2C409.601 333.81 411.311 333.81 412.361 333.2C413.411 332.59 413.411 331.61 412.361 331C411.311 330.39 409.601 330.39 408.551 331C407.501 331.6 407.491 332.59 408.551 333.2Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M587.721 233.58C588.771 234.19 590.481 234.19 591.531 233.58C592.581 232.97 592.581 231.99 591.531 231.38C590.481 230.77 588.771 230.77 587.721 231.38C586.671 231.99 586.671 232.97 587.721 233.58Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M539.531 253.99C540.581 254.6 542.291 254.6 543.341 253.99C544.391 253.38 544.391 252.4 543.341 251.79C542.291 251.18 540.581 251.18 539.531 251.79C538.471 252.4 538.471 253.39 539.531 253.99Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M608.34 204.58C609.39 205.19 611.1 205.19 612.15 204.58C613.2 203.97 613.2 202.99 612.15 202.38C611.1 201.77 609.39 201.77 608.34 202.38C607.29 202.99 607.29 203.98 608.34 204.58Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M278.78 264.97C279.83 265.58 281.54 265.58 282.59 264.97C283.64 264.36 283.64 263.38 282.59 262.77C281.54 262.16 279.83 262.16 278.78 262.77C277.73 263.37 277.73 264.36 278.78 264.97Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M170.151 202.25C171.201 202.86 172.911 202.86 173.961 202.25C175.011 201.64 175.011 200.66 173.961 200.05C172.911 199.44 171.201 199.44 170.151 200.05C169.101 200.65 169.101 201.64 170.151 202.25Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M216.131 163.36C217.181 163.97 218.891 163.97 219.941 163.36C220.991 162.75 220.991 161.77 219.941 161.16C218.891 160.55 217.181 160.55 216.131 161.16C215.081 161.77 215.081 162.76 216.131 163.36Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M242.131 176.83C243.181 177.44 244.891 177.44 245.941 176.83C246.991 176.22 246.991 175.24 245.941 174.63C244.891 174.02 243.181 174.02 242.131 174.63C241.081 175.24 241.081 176.23 242.131 176.83Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M267.11 155.75C268.16 156.36 269.87 156.36 270.92 155.75C271.97 155.14 271.97 154.16 270.92 153.55C269.87 152.94 268.16 152.94 267.11 153.55C266.06 154.16 266.06 155.15 267.11 155.75Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M313 101.83C314.05 102.44 315.76 102.44 316.81 101.83C317.86 101.22 317.86 100.24 316.81 99.6301C315.76 99.0201 314.05 99.0201 313 99.6301C311.95 100.24 311.95 101.22 313 101.83Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M137 58.6801C138.05 59.2901 139.76 59.2901 140.81 58.6801C141.86 58.0701 141.86 57.0901 140.81 56.4801C139.76 55.8701 138.05 55.8701 137 56.4801C135.95 57.0801 135.95 58.0701 137 58.6801Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M206.7 51.72C207.75 52.33 209.46 52.33 210.51 51.72C211.56 51.11 211.56 50.13 210.51 49.52C209.46 48.91 207.75 48.91 206.7 49.52C205.65 50.13 205.65 51.11 206.7 51.72Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M345.051 105.54C346.101 106.15 347.811 106.15 348.861 105.54C349.911 104.93 349.911 103.95 348.861 103.34C347.811 102.73 346.101 102.73 345.051 103.34C344.001 103.94 344.001 104.93 345.051 105.54Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M348.59 85.9001C349.64 86.5101 351.35 86.5101 352.4 85.9001C353.45 85.2901 353.45 84.3101 352.4 83.7001C351.35 83.0901 349.64 83.0901 348.59 83.7001C347.54 84.3101 347.54 85.3001 348.59 85.9001Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M403.77 62.72C404.82 63.33 406.53 63.33 407.58 62.72C408.63 62.11 408.63 61.13 407.58 60.52C406.53 59.91 404.82 59.91 403.77 60.52C402.72 61.13 402.72 62.12 403.77 62.72Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M384.26 55.23C385.31 55.84 387.02 55.84 388.07 55.23C389.12 54.62 389.12 53.64 388.07 53.03C387.02 52.42 385.31 52.42 384.26 53.03C383.21 53.64 383.21 54.62 384.26 55.23Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M582.67 6.55008C583.72 7.16008 585.43 7.16008 586.48 6.55008C587.53 5.94008 587.53 4.96008 586.48 4.35008C585.43 3.74008 583.72 3.74008 582.67 4.35008C581.62 4.96008 581.62 5.94008 582.67 6.55008Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M395.281 35.5801C396.331 36.1901 398.041 36.1901 399.091 35.5801C400.141 34.9701 400.141 33.9901 399.091 33.3801C398.041 32.7701 396.331 32.7701 395.281 33.3801C394.221 33.9901 394.221 34.9701 395.281 35.5801Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M278.051 199.16C279.101 199.77 280.811 199.77 281.861 199.16C282.911 198.55 282.911 197.57 281.861 196.96C280.811 196.35 279.101 196.35 278.051 196.96C277.001 197.56 277.001 198.55 278.051 199.16Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M406.541 134.06C407.591 134.67 409.301 134.67 410.351 134.06C411.401 133.45 411.401 132.47 410.351 131.86C409.301 131.25 407.591 131.25 406.541 131.86C405.481 132.47 405.481 133.45 406.541 134.06Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M517.26 95.7001C518.31 96.3101 520.02 96.3101 521.07 95.7001C522.12 95.0901 522.12 94.1101 521.07 93.5001C520.02 92.8901 518.31 92.8901 517.26 93.5001C516.21 94.1101 516.21 95.1001 517.26 95.7001Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M598.25 53.87C599.3 54.48 601.01 54.48 602.06 53.87C603.11 53.26 603.11 52.28 602.06 51.67C601.01 51.06 599.3 51.06 598.25 51.67C597.2 52.28 597.2 53.26 598.25 53.87Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M572.93 47.11C573.98 47.72 575.69 47.72 576.74 47.11C577.79 46.5 577.79 45.52 576.74 44.91C575.69 44.3 573.98 44.3 572.93 44.91C571.88 45.51 571.88 46.5 572.93 47.11Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M232.53 201.92C233.58 202.53 235.29 202.53 236.34 201.92C237.39 201.31 237.39 200.33 236.34 199.72C235.29 199.11 233.58 199.11 232.53 199.72C231.48 200.33 231.48 201.31 232.53 201.92Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M141.39 291.86C142.44 292.47 144.15 292.47 145.2 291.86C146.25 291.25 146.25 290.27 145.2 289.66C144.15 289.05 142.44 289.05 141.39 289.66C140.34 290.27 140.34 291.25 141.39 291.86Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M104.771 195.76C105.821 196.37 107.531 196.37 108.581 195.76C109.631 195.15 109.631 194.17 108.581 193.56C107.531 192.95 105.821 192.95 104.771 193.56C103.721 194.17 103.721 195.16 104.771 195.76Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M149.33 258.21C150.38 258.82 152.09 258.82 153.14 258.21C154.19 257.6 154.19 256.62 153.14 256.01C152.09 255.4 150.38 255.4 149.33 256.01C148.28 256.62 148.28 257.6 149.33 258.21Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M0.79043 261.83C1.84043 262.44 3.55043 262.44 4.60043 261.83C5.65043 261.22 5.65043 260.24 4.60043 259.63C3.55043 259.02 1.84043 259.02 0.79043 259.63C-0.25957 260.24 -0.25957 261.22 0.79043 261.83Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M296.741 254.26C297.791 254.87 299.501 254.87 300.551 254.26C301.601 253.65 301.601 252.67 300.551 252.06C299.501 251.45 297.791 251.45 296.741 252.06C295.691 252.66 295.691 253.65 296.741 254.26Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M410.295 260.287L387.713 273.314L388.013 273.834L410.595 260.807L410.295 260.287Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M304.19 204.83H291.16L291.09 204.78L279.5 198.1L279.8 197.57L291.32 204.22H304.19V204.83Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M218.191 162.52L217.891 162L249.531 143.73H271.061L271.131 143.77L299.001 159.86H316.601V160.47H298.841L298.771 160.43L270.901 144.33H249.701L218.191 162.52Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M300.61 185.91L269.01 167.66H258.62L244.72 175.69L244.42 175.16L258.46 167.06H269.17L269.24 167.1L300.91 185.39L300.61 185.91Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M292.411 180.65H291.811V168.16L268.861 154.92L269.161 154.39L292.411 167.82V180.65Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M475.611 206.99H452.131V206.39H475.451L501.981 191.07L502.281 191.59L475.611 206.99Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M108.021 368.08L107.721 367.56L188.541 320.9V291.25L188.691 291.16L330.601 209.23L330.901 209.75L189.141 291.6V321.25L188.991 321.34L108.021 368.08Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M478.67 180.27L458.58 168.67H450.58L439.86 174.86H427.59V174.25H439.7L450.42 168.06H458.74L458.81 168.1L478.97 179.75L478.67 180.27Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M384.6 158.45L384.3 157.92L422.39 135.93L422.4 111L379.1 86.0101V76.6201L379.25 76.5301L405.52 61.3601L405.83 61.8901L379.7 76.9701V85.6601L423 110.65V136.28L422.85 136.36L384.6 158.45Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M393.721 153H393.121V141.46L393.271 141.37L408.291 132.7L408.591 133.22L393.731 141.81L393.721 153Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M471.98 140.76L471.68 140.24L562.15 88.0101V74.3601L562.31 74.2701L599.89 52.5701L600.19 53.0901L562.76 74.7101V88.3501L562.61 88.4401L471.98 140.76Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M505.211 102.83H504.611V86.2001L504.761 86.1101L574.681 45.7401L574.981 46.2701L505.211 86.5501V102.83Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M450.081 140.59L449.781 140.07L495.361 113.75V108L495.511 107.91L519.021 94.3401L519.321 94.8601L495.961 108.35V114.1L495.811 114.19L450.081 140.59Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M228.98 306.6L228.68 306.08L255.51 290.58V273.24L268.61 265.68L268.91 266.2L256.12 273.59V290.93L255.96 291.02L228.98 306.6Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M270.831 211.78H252.821L252.751 211.74L234.281 201.08L234.581 200.56L252.981 211.18H270.831V211.78Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M172.731 233.1H172.651L172.581 233.06L107.181 195.3L107.481 194.78L172.811 232.5H255.221V233.1H172.731Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M374.2 152.09H373.59V120.16L347.24 104.95L347.55 104.43L374.2 119.81V152.09Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M374.04 133.41L373.74 132.89L392.93 121.81V109.65L350.35 85.06L350.65 84.54L393.54 109.31V122.16L393.39 122.24L374.04 133.41Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M541.28 253.15L493.18 225.39H452.26V224.78H493.35L493.42 224.82L541.58 252.63L541.28 253.15Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M546.791 208.08L546.721 208.04L533.611 200.47H486.811V199.87H533.771L533.841 199.91L546.951 207.48H575.861V208.08H546.791Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M470.13 198.48L448.3 185.88H431.9V185.28H448.46L448.53 185.32L470.43 197.96L470.13 198.48Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M201.091 370.04L200.791 369.52L232.931 350.96V323.16L233.081 323.07L276.201 298.18L276.501 298.7L233.531 323.51V351.31L233.381 351.4L201.091 370.04Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M361.59 158.98C361.27 158.98 360.93 158.88 360.58 158.68L354.25 155.03L354.55 154.51L360.88 158.16C361.3 158.4 361.67 158.45 361.94 158.29C362.21 158.14 362.35 157.79 362.35 157.31V155.78C362.35 155.07 362.6 154.54 363.06 154.27C363.52 154 364.11 154.05 364.72 154.41L366.04 155.17L365.74 155.69L364.42 154.93C364 154.69 363.63 154.64 363.36 154.8C363.09 154.95 362.95 155.3 362.95 155.78V157.31C362.95 158.02 362.7 158.55 362.24 158.82C362.04 158.93 361.82 158.98 361.59 158.98Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M359.119 160.4C358.799 160.4 358.459 160.3 358.109 160.1L351.779 156.45L352.079 155.93L358.409 159.58C358.829 159.82 359.199 159.87 359.469 159.71C359.739 159.56 359.879 159.21 359.879 158.73V157.2C359.879 156.49 360.129 155.96 360.589 155.69C361.049 155.42 361.639 155.47 362.249 155.83L363.569 156.59L363.269 157.11L361.949 156.35C361.529 156.11 361.159 156.06 360.889 156.22C360.619 156.37 360.479 156.72 360.479 157.2V158.73C360.479 159.44 360.229 159.97 359.769 160.24C359.579 160.34 359.359 160.4 359.119 160.4Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M356.66 161.82C356.34 161.82 356 161.72 355.65 161.52L349.32 157.87L349.62 157.35L355.95 161C356.37 161.24 356.74 161.29 357.01 161.13C357.28 160.98 357.42 160.63 357.42 160.15V158.62C357.42 157.91 357.67 157.38 358.13 157.11C358.59 156.84 359.18 156.89 359.79 157.24L361.11 158L360.81 158.52L359.49 157.76C359.07 157.52 358.7 157.47 358.43 157.63C358.16 157.78 358.02 158.13 358.02 158.61V160.14C358.02 160.85 357.77 161.38 357.31 161.65C357.12 161.76 356.9 161.82 356.66 161.82Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M354.199 163.23C353.879 163.23 353.539 163.13 353.189 162.93L346.859 159.28L347.159 158.76L353.489 162.41C353.909 162.65 354.279 162.7 354.549 162.54C354.819 162.39 354.959 162.04 354.959 161.56V160.03C354.959 159.32 355.209 158.79 355.669 158.52C356.129 158.25 356.719 158.3 357.329 158.66L358.649 159.42L358.349 159.94L357.029 159.18C356.609 158.94 356.239 158.89 355.969 159.05C355.699 159.2 355.559 159.55 355.559 160.03V161.56C355.559 162.27 355.309 162.8 354.849 163.07C354.659 163.18 354.439 163.23 354.199 163.23Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M351.74 164.65C351.42 164.65 351.08 164.55 350.73 164.35L344.4 160.7L344.7 160.18L351.03 163.83C351.45 164.07 351.82 164.12 352.09 163.96C352.36 163.81 352.5 163.46 352.5 162.98V161.45C352.5 160.74 352.75 160.21 353.21 159.94C353.67 159.67 354.26 159.72 354.87 160.08L356.19 160.84L355.89 161.36L354.57 160.6C354.15 160.36 353.78 160.31 353.51 160.47C353.24 160.62 353.1 160.97 353.1 161.45V162.98C353.1 163.69 352.85 164.22 352.39 164.49C352.2 164.59 351.98 164.65 351.74 164.65Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M349.279 166.06C348.959 166.06 348.619 165.96 348.269 165.76L341.939 162.11L342.239 161.59L348.569 165.24C348.989 165.48 349.359 165.53 349.629 165.37C349.899 165.22 350.039 164.87 350.039 164.39V162.86C350.039 162.15 350.289 161.62 350.749 161.35C351.209 161.09 351.799 161.13 352.409 161.48L353.729 162.24L353.429 162.76L352.109 162C351.689 161.76 351.319 161.71 351.049 161.87C350.779 162.02 350.639 162.37 350.639 162.85V164.38C350.639 165.09 350.389 165.62 349.929 165.89C349.729 166.01 349.519 166.06 349.279 166.06Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M346.82 167.48C346.5 167.48 346.16 167.38 345.81 167.18L339.48 163.53L339.78 163.01L346.11 166.66C346.53 166.9 346.9 166.95 347.17 166.79C347.44 166.64 347.58 166.29 347.58 165.81V164.28C347.58 163.57 347.83 163.04 348.29 162.77C348.75 162.5 349.34 162.55 349.95 162.91L351.27 163.67L350.97 164.19L349.65 163.43C349.23 163.19 348.86 163.14 348.59 163.29C348.32 163.44 348.18 163.79 348.18 164.27V165.8C348.18 166.51 347.93 167.04 347.47 167.31C347.27 167.42 347.05 167.48 346.82 167.48Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M344.36 168.89C344.04 168.89 343.7 168.79 343.35 168.59L337.02 164.94L337.32 164.42L343.65 168.07C344.07 168.31 344.44 168.36 344.71 168.2C344.97 168.05 345.12 167.7 345.12 167.22V165.69C345.12 164.98 345.37 164.45 345.83 164.18C346.29 163.91 346.88 163.96 347.49 164.32L348.81 165.08L348.51 165.6L347.19 164.84C346.78 164.6 346.4 164.55 346.13 164.71C345.86 164.86 345.72 165.21 345.72 165.69V167.22C345.72 167.93 345.47 168.46 345.01 168.72C344.81 168.84 344.59 168.89 344.36 168.89Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M341.901 170.31C341.581 170.31 341.241 170.21 340.891 170.01L334.561 166.36L334.861 165.84L341.191 169.49C341.611 169.73 341.981 169.78 342.251 169.62C342.521 169.47 342.661 169.12 342.661 168.64V167.11C342.661 166.4 342.911 165.87 343.371 165.6C343.831 165.33 344.421 165.38 345.031 165.73L346.351 166.49L346.051 167.01L344.731 166.25C344.311 166.01 343.941 165.96 343.671 166.12C343.401 166.27 343.261 166.62 343.261 167.1V168.63C343.261 169.34 343.011 169.87 342.551 170.14C342.351 170.25 342.131 170.31 341.901 170.31Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M339.44 171.72C339.12 171.72 338.78 171.62 338.43 171.42L332.1 167.77L332.4 167.25L338.73 170.9C339.15 171.14 339.52 171.19 339.79 171.03C340.06 170.88 340.2 170.53 340.2 170.05V168.52C340.2 167.81 340.45 167.28 340.91 167.01C341.37 166.74 341.96 166.79 342.57 167.15L343.89 167.91L343.59 168.43L342.27 167.67C341.85 167.43 341.48 167.38 341.21 167.54C340.94 167.69 340.8 168.04 340.8 168.52V170.05C340.8 170.76 340.55 171.29 340.09 171.56C339.89 171.67 339.67 171.72 339.44 171.72Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M336.981 173.14C336.661 173.14 336.321 173.04 335.971 172.84L329.641 169.19L329.941 168.67L336.271 172.32C336.691 172.56 337.061 172.61 337.331 172.45C337.601 172.3 337.741 171.95 337.741 171.47V169.94C337.741 169.23 337.991 168.7 338.451 168.43C338.911 168.16 339.501 168.21 340.111 168.57L341.431 169.33L341.131 169.85L339.811 169.09C339.391 168.85 339.021 168.8 338.751 168.96C338.481 169.11 338.341 169.46 338.341 169.94V171.47C338.341 172.18 338.091 172.71 337.631 172.98C337.431 173.08 337.211 173.14 336.981 173.14Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M334.52 174.56C334.2 174.56 333.86 174.46 333.51 174.26L327.18 170.61L327.48 170.09L333.81 173.74C334.23 173.98 334.6 174.03 334.87 173.87C335.14 173.72 335.28 173.37 335.28 172.89V171.36C335.28 170.65 335.53 170.12 335.99 169.85C336.45 169.58 337.04 169.63 337.65 169.98L338.97 170.74L338.67 171.26L337.35 170.5C336.93 170.26 336.56 170.21 336.29 170.37C336.02 170.52 335.88 170.87 335.88 171.35V172.88C335.88 173.59 335.63 174.12 335.17 174.39C334.97 174.5 334.75 174.56 334.52 174.56Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M332.051 175.97C331.731 175.97 331.391 175.87 331.041 175.67L324.711 172.02L325.011 171.5L331.341 175.15C331.761 175.39 332.131 175.44 332.401 175.28C332.671 175.13 332.811 174.78 332.811 174.3V172.77C332.811 172.06 333.061 171.53 333.521 171.26C333.981 170.99 334.571 171.04 335.181 171.4L336.501 172.16L336.201 172.68L334.881 171.92C334.461 171.68 334.091 171.63 333.821 171.79C333.551 171.94 333.411 172.29 333.411 172.77V174.3C333.411 175.01 333.161 175.54 332.701 175.8C332.511 175.92 332.291 175.97 332.051 175.97Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M329.59 177.39C329.27 177.39 328.93 177.29 328.58 177.09L322.25 173.44L322.55 172.92L328.88 176.57C329.3 176.81 329.67 176.86 329.94 176.7C330.21 176.55 330.35 176.2 330.35 175.72V174.19C330.35 173.48 330.6 172.95 331.06 172.68C331.52 172.41 332.11 172.46 332.72 172.82L334.04 173.58L333.74 174.1L332.42 173.34C332 173.1 331.63 173.05 331.36 173.21C331.09 173.36 330.95 173.71 330.95 174.19V175.72C330.95 176.43 330.7 176.96 330.24 177.23C330.05 177.33 329.83 177.39 329.59 177.39Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M327.131 178.8C326.811 178.8 326.471 178.7 326.121 178.5L319.791 174.85L320.091 174.33L326.421 177.98C326.841 178.22 327.211 178.27 327.481 178.11C327.751 177.96 327.891 177.61 327.891 177.13V175.6C327.891 174.89 328.141 174.36 328.601 174.09C329.061 173.82 329.651 173.87 330.261 174.22L331.581 174.98L331.281 175.5L329.961 174.74C329.541 174.5 329.171 174.45 328.901 174.61C328.631 174.76 328.491 175.11 328.491 175.59V177.12C328.491 177.83 328.241 178.36 327.781 178.63C327.591 178.75 327.371 178.8 327.131 178.8Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M324.67 180.22C324.35 180.22 324.01 180.12 323.66 179.92L317.33 176.27L317.63 175.75L323.96 179.4C324.38 179.64 324.75 179.69 325.02 179.53C325.29 179.38 325.43 179.03 325.43 178.55V177.02C325.43 176.31 325.68 175.78 326.14 175.51C326.6 175.24 327.19 175.29 327.8 175.65L329.12 176.41L328.82 176.93L327.5 176.17C327.08 175.93 326.71 175.88 326.44 176.04C326.17 176.19 326.03 176.54 326.03 177.02V178.55C326.03 179.26 325.78 179.79 325.32 180.06C325.13 180.16 324.9 180.22 324.67 180.22Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M322.211 181.64C321.891 181.64 321.551 181.54 321.201 181.34L314.871 177.69L315.171 177.17L321.501 180.82C321.921 181.06 322.291 181.11 322.561 180.95C322.831 180.8 322.971 180.45 322.971 179.97V178.44C322.971 177.73 323.221 177.2 323.681 176.93C324.141 176.66 324.731 176.71 325.341 177.07L326.661 177.83L326.361 178.35L325.041 177.59C324.621 177.35 324.251 177.3 323.981 177.46C323.711 177.61 323.571 177.96 323.571 178.44V179.97C323.571 180.68 323.321 181.21 322.861 181.48C322.661 181.58 322.441 181.64 322.211 181.64Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M319.75 183.05C319.43 183.05 319.09 182.95 318.74 182.75L312.41 179.1L312.71 178.58L319.04 182.23C319.46 182.47 319.83 182.52 320.1 182.36C320.37 182.21 320.51 181.86 320.51 181.38V179.85C320.51 179.14 320.76 178.61 321.22 178.34C321.68 178.07 322.27 178.12 322.88 178.47L324.2 179.23L323.9 179.75L322.58 178.99C322.16 178.75 321.79 178.7 321.52 178.86C321.25 179.01 321.11 179.36 321.11 179.84V181.37C321.11 182.08 320.86 182.61 320.4 182.88C320.2 182.99 319.98 183.05 319.75 183.05Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M317.291 184.47C316.971 184.47 316.631 184.37 316.281 184.17L309.951 180.52L310.251 180L316.581 183.65C317.001 183.89 317.371 183.94 317.641 183.78C317.911 183.63 318.051 183.28 318.051 182.8V181.27C318.051 180.56 318.301 180.03 318.761 179.76C319.221 179.49 319.811 179.54 320.421 179.9L321.741 180.66L321.441 181.18L320.121 180.42C319.701 180.18 319.331 180.13 319.061 180.29C318.791 180.44 318.651 180.79 318.651 181.27V182.8C318.651 183.51 318.401 184.04 317.941 184.31C317.741 184.41 317.521 184.47 317.291 184.47Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M314.83 185.88C314.51 185.88 314.17 185.78 313.82 185.58L307.49 181.93L307.79 181.41L314.12 185.06C314.54 185.3 314.91 185.35 315.18 185.19C315.45 185.04 315.59 184.69 315.59 184.21V182.68C315.59 181.97 315.84 181.44 316.3 181.17C316.76 180.9 317.35 180.95 317.96 181.31L319.28 182.07L318.98 182.59L317.66 181.83C317.24 181.59 316.87 181.54 316.6 181.7C316.33 181.85 316.19 182.2 316.19 182.68V184.21C316.19 184.92 315.94 185.45 315.48 185.72C315.28 185.83 315.06 185.88 314.83 185.88Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M371.669 159.1C371.429 159.1 371.209 159.04 371.019 158.93C370.559 158.66 370.309 158.13 370.309 157.42V155.89C370.309 155.41 370.159 155.06 369.899 154.91C369.629 154.76 369.259 154.8 368.839 155.04L367.519 155.8L367.219 155.28L368.539 154.52C369.149 154.17 369.739 154.12 370.199 154.39C370.659 154.66 370.909 155.19 370.909 155.9V157.43C370.909 157.91 371.059 158.26 371.319 158.41C371.589 158.56 371.959 158.52 372.379 158.28L378.709 154.63L379.009 155.15L372.679 158.8C372.329 159 371.979 159.1 371.669 159.1Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M374.13 160.51C373.9 160.51 373.68 160.45 373.48 160.34C373.02 160.07 372.77 159.54 372.77 158.83V157.3C372.77 156.82 372.62 156.47 372.36 156.32C372.1 156.17 371.72 156.22 371.3 156.45L369.98 157.21L369.68 156.69L371 155.93C371.61 155.58 372.2 155.53 372.66 155.8C373.12 156.07 373.37 156.6 373.37 157.31V158.84C373.37 159.32 373.52 159.67 373.78 159.82C374.05 159.97 374.42 159.92 374.84 159.69L381.17 156.04L381.47 156.56L375.14 160.21C374.79 160.41 374.45 160.51 374.13 160.51Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M376.589 161.93C376.359 161.93 376.139 161.87 375.939 161.76C375.479 161.49 375.229 160.96 375.229 160.25V158.72C375.229 158.24 375.079 157.89 374.819 157.74C374.549 157.59 374.179 157.64 373.759 157.87L372.439 158.63L372.139 158.11L373.459 157.35C374.069 157 374.659 156.95 375.119 157.22C375.579 157.49 375.829 158.02 375.829 158.73V160.26C375.829 160.74 375.979 161.09 376.239 161.24C376.509 161.39 376.879 161.34 377.299 161.11L383.629 157.46L383.929 157.98L377.599 161.63C377.249 161.83 376.909 161.93 376.589 161.93Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M379.05 163.35C378.81 163.35 378.59 163.29 378.4 163.18C377.94 162.91 377.69 162.38 377.69 161.67V160.14C377.69 159.66 377.54 159.31 377.28 159.16C377.01 159.01 376.64 159.05 376.22 159.29L374.9 160.05L374.6 159.53L375.92 158.77C376.53 158.42 377.12 158.37 377.58 158.64C378.04 158.91 378.29 159.44 378.29 160.15V161.68C378.29 162.16 378.44 162.51 378.7 162.66C378.97 162.81 379.34 162.77 379.76 162.53L386.09 158.88L386.39 159.4L380.06 163.05C379.71 163.24 379.37 163.35 379.05 163.35Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M381.509 164.76C381.279 164.76 381.059 164.7 380.859 164.59C380.399 164.32 380.149 163.79 380.149 163.08V161.55C380.149 161.07 379.999 160.72 379.739 160.57C379.469 160.42 379.099 160.47 378.679 160.7L377.359 161.46L377.059 160.94L378.379 160.18C378.989 159.83 379.579 159.78 380.039 160.04C380.499 160.31 380.749 160.84 380.749 161.55V163.08C380.749 163.56 380.899 163.91 381.159 164.06C381.429 164.21 381.799 164.16 382.219 163.93L388.549 160.28L388.849 160.8L382.519 164.45C382.169 164.66 381.829 164.76 381.509 164.76Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M383.97 166.18C383.74 166.18 383.52 166.12 383.32 166.01C382.86 165.74 382.61 165.21 382.61 164.5V162.97C382.61 162.49 382.46 162.14 382.2 161.99C381.93 161.84 381.56 161.89 381.14 162.12L379.82 162.88L379.52 162.36L380.84 161.6C381.45 161.25 382.04 161.2 382.5 161.46C382.96 161.73 383.21 162.26 383.21 162.97V164.5C383.21 164.98 383.36 165.33 383.62 165.48C383.89 165.63 384.26 165.58 384.68 165.35L391.01 161.7L391.31 162.22L384.98 165.87C384.63 166.08 384.29 166.18 383.97 166.18Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M386.438 167.59C386.198 167.59 385.978 167.53 385.788 167.42C385.328 167.15 385.078 166.62 385.078 165.91V164.38C385.078 163.9 384.928 163.55 384.668 163.4C384.408 163.24 384.028 163.29 383.608 163.53L382.288 164.29L381.988 163.77L383.308 163.01C383.918 162.66 384.508 162.61 384.968 162.88C385.428 163.15 385.678 163.68 385.678 164.39V165.92C385.678 166.4 385.828 166.75 386.088 166.9C386.358 167.05 386.728 167.01 387.148 166.77L393.478 163.12L393.778 163.64L387.448 167.29C387.088 167.49 386.748 167.59 386.438 167.59Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M388.899 169.01C388.659 169.01 388.449 168.95 388.249 168.84C387.789 168.57 387.539 168.04 387.539 167.34V165.81C387.539 165.33 387.389 164.98 387.129 164.83C386.859 164.68 386.489 164.73 386.069 164.96L384.749 165.72L384.449 165.2L385.769 164.44C386.379 164.09 386.969 164.04 387.429 164.3C387.889 164.57 388.139 165.1 388.139 165.81V167.34C388.139 167.82 388.289 168.17 388.549 168.32C388.809 168.47 389.189 168.42 389.609 168.19L395.939 164.54L396.239 165.06L389.909 168.71C389.559 168.91 389.209 169.01 388.899 169.01Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M391.358 170.43C391.118 170.43 390.898 170.37 390.708 170.26C390.248 169.99 389.998 169.46 389.998 168.76V167.23C389.998 166.75 389.848 166.4 389.588 166.25C389.318 166.1 388.948 166.15 388.528 166.38L387.208 167.14L386.908 166.62L388.228 165.86C388.838 165.51 389.428 165.46 389.888 165.72C390.348 165.99 390.598 166.52 390.598 167.23V168.76C390.598 169.24 390.748 169.59 391.008 169.74C391.268 169.89 391.648 169.84 392.068 169.61L398.398 165.96L398.698 166.48L392.368 170.13C392.018 170.32 391.668 170.43 391.358 170.43Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M393.819 171.84C393.579 171.84 393.359 171.78 393.169 171.67C392.709 171.4 392.459 170.87 392.459 170.16V168.63C392.459 168.15 392.309 167.8 392.049 167.65C391.789 167.49 391.409 167.54 390.989 167.78L389.669 168.54L389.369 168.02L390.689 167.26C391.299 166.91 391.889 166.86 392.349 167.13C392.809 167.4 393.059 167.93 393.059 168.64V170.17C393.059 170.65 393.209 171 393.469 171.15C393.739 171.3 394.109 171.26 394.529 171.02L400.859 167.37L401.159 167.89L394.829 171.54C394.479 171.74 394.129 171.84 393.819 171.84Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M396.278 173.26C396.038 173.26 395.818 173.2 395.628 173.09C395.168 172.82 394.918 172.29 394.918 171.58V170.05C394.918 169.57 394.768 169.22 394.508 169.07C394.238 168.92 393.868 168.97 393.448 169.2L392.128 169.96L391.828 169.44L393.148 168.68C393.758 168.33 394.348 168.28 394.808 168.54C395.268 168.81 395.518 169.34 395.518 170.05V171.58C395.518 172.06 395.668 172.41 395.928 172.56C396.198 172.71 396.568 172.66 396.988 172.43L403.318 168.78L403.618 169.3L397.288 172.95C396.938 173.15 396.598 173.26 396.278 173.26Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M398.739 174.67C398.499 174.67 398.279 174.61 398.089 174.5C397.629 174.23 397.379 173.7 397.379 173V171.47C397.379 170.99 397.229 170.64 396.969 170.49C396.699 170.34 396.329 170.39 395.909 170.62L394.589 171.38L394.289 170.86L395.609 170.1C396.219 169.75 396.809 169.7 397.269 169.96C397.729 170.23 397.979 170.76 397.979 171.47V173C397.979 173.48 398.129 173.83 398.389 173.98C398.659 174.14 399.029 174.09 399.449 173.85L405.779 170.2L406.079 170.72L399.749 174.37C399.399 174.57 399.059 174.67 398.739 174.67Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M401.198 176.09C400.958 176.09 400.738 176.03 400.548 175.92C400.088 175.65 399.838 175.12 399.838 174.41V172.88C399.838 172.4 399.688 172.05 399.428 171.9C399.168 171.74 398.788 171.79 398.368 172.03L397.048 172.79L396.748 172.27L398.068 171.51C398.678 171.16 399.268 171.11 399.728 171.38C400.188 171.65 400.438 172.18 400.438 172.89V174.42C400.438 174.9 400.588 175.25 400.848 175.4C401.118 175.55 401.488 175.51 401.908 175.27L408.238 171.62L408.538 172.14L402.208 175.79C401.858 175.99 401.518 176.09 401.198 176.09Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M403.659 177.5C403.429 177.5 403.209 177.44 403.009 177.33C402.549 177.06 402.299 176.53 402.299 175.82V174.29C402.299 173.81 402.149 173.46 401.889 173.31C401.619 173.16 401.249 173.21 400.829 173.44L399.509 174.2L399.209 173.68L400.529 172.92C401.139 172.57 401.729 172.52 402.189 172.78C402.649 173.05 402.899 173.58 402.899 174.29V175.82C402.899 176.3 403.049 176.65 403.309 176.8C403.569 176.95 403.949 176.9 404.369 176.67L410.699 173.02L410.999 173.54L404.669 177.19C404.319 177.4 403.979 177.5 403.659 177.5Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M406.118 178.92C405.888 178.92 405.668 178.86 405.468 178.75C405.008 178.48 404.758 177.95 404.758 177.24V175.71C404.758 175.23 404.608 174.88 404.348 174.73C404.078 174.58 403.708 174.62 403.288 174.86L401.968 175.62L401.668 175.1L402.988 174.34C403.598 173.99 404.188 173.94 404.648 174.2C405.108 174.47 405.358 175 405.358 175.71V177.24C405.358 177.72 405.508 178.07 405.768 178.22C406.028 178.37 406.408 178.33 406.828 178.09L413.158 174.44L413.458 174.96L407.128 178.61C406.778 178.82 406.438 178.92 406.118 178.92Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M408.579 180.33C408.349 180.33 408.129 180.27 407.929 180.16C407.469 179.89 407.219 179.36 407.219 178.65V177.12C407.219 176.64 407.069 176.29 406.809 176.14C406.539 175.98 406.169 176.03 405.749 176.27L404.429 177.03L404.129 176.51L405.449 175.75C406.059 175.4 406.649 175.35 407.109 175.62C407.569 175.89 407.819 176.42 407.819 177.13V178.66C407.819 179.14 407.969 179.49 408.229 179.64C408.499 179.79 408.869 179.74 409.289 179.51L415.619 175.86L415.919 176.38L409.589 180.03C409.239 180.23 408.899 180.33 408.579 180.33Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M411.038 181.75C410.808 181.75 410.588 181.69 410.388 181.58C409.928 181.31 409.678 180.78 409.678 180.07V178.54C409.678 178.06 409.528 177.71 409.268 177.56C408.998 177.41 408.628 177.46 408.208 177.69L406.888 178.45L406.588 177.93L407.908 177.17C408.518 176.82 409.108 176.77 409.568 177.03C410.028 177.3 410.278 177.83 410.278 178.54V180.07C410.278 180.55 410.428 180.9 410.688 181.05C410.958 181.2 411.328 181.15 411.748 180.92L418.078 177.27L418.378 177.79L412.048 181.44C411.698 181.65 411.358 181.75 411.038 181.75Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M413.509 183.17C413.279 183.17 413.059 183.11 412.859 183C412.399 182.73 412.149 182.2 412.149 181.49V179.96C412.149 179.48 411.999 179.13 411.739 178.98C411.469 178.83 411.099 178.88 410.679 179.11L409.359 179.87L409.059 179.35L410.379 178.59C410.989 178.24 411.579 178.19 412.039 178.45C412.499 178.72 412.749 179.25 412.749 179.96V181.49C412.749 181.97 412.899 182.32 413.159 182.47C413.419 182.62 413.799 182.58 414.219 182.34L420.549 178.69L420.849 179.21L414.519 182.86C414.169 183.06 413.819 183.17 413.509 183.17Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M415.97 184.58C415.74 184.58 415.52 184.52 415.32 184.41C414.86 184.14 414.61 183.61 414.61 182.9V181.37C414.61 180.89 414.46 180.54 414.2 180.39C413.94 180.23 413.56 180.28 413.14 180.52L411.82 181.28L411.52 180.76L412.84 180C413.45 179.65 414.04 179.6 414.5 179.87C414.96 180.14 415.21 180.67 415.21 181.38V182.91C415.21 183.39 415.36 183.74 415.62 183.89C415.89 184.04 416.26 183.99 416.68 183.76L423.01 180.11L423.31 180.63L416.98 184.28C416.63 184.48 416.28 184.58 415.97 184.58Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M418.428 186C418.198 186 417.979 185.94 417.779 185.83C417.319 185.56 417.069 185.03 417.069 184.32V182.79C417.069 182.31 416.919 181.96 416.659 181.81C416.389 181.66 416.019 181.71 415.599 181.94L414.279 182.7L413.979 182.18L415.298 181.42C415.908 181.07 416.498 181.02 416.958 181.29C417.418 181.56 417.669 182.09 417.669 182.8V184.33C417.669 184.81 417.818 185.16 418.078 185.31C418.348 185.46 418.719 185.41 419.139 185.18L425.469 181.53L425.768 182.05L419.439 185.7C419.089 185.9 418.738 186 418.428 186Z"
        fill="#42E8E0"
      />
      <motion.path
        opacity="0.8"
        d="M366.649 223.08L318.029 195L366.649 166.93L415.269 195.01L366.649 223.08Z"
        fill="url(#paint0_radial)"
      />
      <motion.path
        d="M415.269 183V188.62L366.649 216.69L318.029 188.62V183H415.269Z"
        fill="url(#paint1_linear)"
      />
      <motion.path
        d="M366.649 211.07L318.029 183L366.649 154.93L415.269 183L366.649 211.07Z"
        fill="#BCBEFF"
      />
      <motion.path
        d="M366.649 210.41L318.589 182.67L318.029 183L366.649 211.07L415.269 183L414.699 182.67L366.649 210.41Z"
        fill="white"
      />
      <motion.path
        d="M366.65 204.59L329.25 183L366.65 161.41L404.04 183L366.65 204.59Z"
        fill="#0F054C"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 204.59L329.25 183L366.65 161.41L404.04 183L366.65 204.59Z"
        fill="url(#paint2_linear)"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 155.97L404.04 177.56V183L366.65 161.41V155.97Z"
        fill="url(#paint3_linear)"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 161.41L329.25 183V177.56L366.65 155.97V161.41Z"
        fill="url(#paint4_linear)"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 199.15L329.25 177.56L366.65 155.97L404.04 177.56L366.65 199.15Z"
        fill="url(#paint5_linear)"
      />
      <motion.path
        opacity="0.5"
        d="M366.649 186.19L351.699 177.56L366.649 168.93L381.589 177.56L366.649 186.19Z"
        fill="#42E8E0"
      />
      <motion.path
        opacity="0.5"
        d="M366.649 169.73L380.899 177.96L381.589 177.56L366.649 168.93L351.699 177.56L352.389 177.96L366.649 169.73Z"
        fill="#42E8E0"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 199.15V204.59L404.04 183V177.56L366.65 199.15Z"
        fill="url(#paint6_linear)"
      />
      <motion.path
        opacity="0.5"
        d="M329.25 177.56V183L366.65 204.59V199.15L329.25 177.56Z"
        fill="url(#paint7_linear)"
      />
      <motion.path
        opacity="0.8"
        d="M366.65 198.56L329.77 177.26L329.25 177.56L366.65 199.15L404.04 177.56L403.53 177.26L366.65 198.56Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M378.699 223.47L372.369 219.82C371.209 219.15 370.299 217.57 370.299 216.23V214.7C370.299 213.57 369.499 212.2 368.529 211.63L367.209 210.87L367.509 210.35L368.829 211.11C369.989 211.78 370.899 213.36 370.899 214.7V216.23C370.899 217.34 371.709 218.74 372.669 219.3L378.999 222.95L378.699 223.47Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M381.17 222.05L374.84 218.4C373.68 217.73 372.77 216.15 372.77 214.81V213.28C372.77 212.15 371.98 210.78 371 210.21L369.68 209.45L369.98 208.93L371.3 209.69C372.46 210.36 373.37 211.94 373.37 213.28V214.81C373.37 215.92 374.18 217.32 375.14 217.88L381.47 221.53L381.17 222.05Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M383.629 220.63L377.299 216.98C376.139 216.31 375.229 214.73 375.229 213.39V211.86C375.229 210.75 374.419 209.35 373.459 208.79L372.139 208.03L372.439 207.51L373.759 208.27C374.919 208.94 375.829 210.52 375.829 211.86V213.39C375.829 214.5 376.639 215.9 377.599 216.46L383.929 220.11L383.629 220.63Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M386.09 219.21L379.76 215.56C378.6 214.89 377.69 213.31 377.69 211.97V210.44C377.69 209.33 376.88 207.93 375.92 207.37L374.6 206.61L374.9 206.09L376.22 206.85C377.38 207.52 378.29 209.1 378.29 210.44V211.97C378.29 213.08 379.1 214.48 380.06 215.04L386.39 218.69L386.09 219.21Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M388.549 217.79L382.219 214.14C381.059 213.47 380.149 211.89 380.149 210.55V209.02C380.149 207.91 379.339 206.51 378.379 205.95L377.059 205.19L377.359 204.67L378.679 205.43C379.839 206.1 380.749 207.68 380.749 209.02V210.55C380.749 211.68 381.539 213.05 382.519 213.62L388.849 217.27L388.549 217.79Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M391.01 216.38L384.68 212.73C383.52 212.06 382.61 210.48 382.61 209.14V207.61C382.61 206.5 381.8 205.1 380.84 204.54L379.52 203.78L379.82 203.26L381.14 204.02C382.3 204.69 383.21 206.27 383.21 207.61V209.14C383.21 210.25 384.02 211.65 384.98 212.21L391.31 215.86L391.01 216.38Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M393.469 214.96L387.139 211.31C385.979 210.64 385.069 209.06 385.069 207.72V206.19C385.069 205.06 384.278 203.69 383.298 203.12L381.979 202.36L382.279 201.84L383.599 202.6C384.759 203.27 385.669 204.85 385.669 206.19V207.72C385.669 208.83 386.479 210.23 387.439 210.79L393.768 214.44L393.469 214.96Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M395.929 213.54L389.599 209.89C388.439 209.22 387.529 207.64 387.529 206.3V204.77C387.529 203.66 386.719 202.26 385.759 201.7L384.439 200.94L384.739 200.42L386.059 201.18C387.219 201.85 388.129 203.43 388.129 204.77V206.3C388.129 207.43 388.929 208.8 389.899 209.37L396.229 213.02L395.929 213.54Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M398.388 212.12L392.058 208.47C390.898 207.8 389.988 206.22 389.988 204.88V203.35C389.988 202.24 389.178 200.84 388.218 200.28L386.898 199.52L387.198 199L388.518 199.76C389.678 200.43 390.588 202.01 390.588 203.35V204.88C390.588 206.01 391.378 207.38 392.358 207.95L398.688 211.6L398.388 212.12Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M400.849 210.7L394.519 207.05C393.359 206.38 392.449 204.8 392.449 203.46V201.93C392.449 200.82 391.639 199.42 390.679 198.86L389.359 198.1L389.659 197.58L390.979 198.34C392.139 199.01 393.049 200.59 393.049 201.93V203.46C393.049 204.59 393.839 205.96 394.819 206.53L401.149 210.18L400.849 210.7Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M403.308 209.28L396.978 205.63C395.818 204.96 394.908 203.38 394.908 202.04V200.51C394.908 199.4 394.098 198 393.138 197.44L391.818 196.68L392.118 196.16L393.438 196.92C394.598 197.59 395.508 199.17 395.508 200.51V202.04C395.508 203.15 396.318 204.55 397.278 205.11L403.608 208.76L403.308 209.28Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M405.779 207.87L399.449 204.22C398.289 203.55 397.379 201.97 397.379 200.63V199.1C397.379 197.97 396.589 196.6 395.609 196.03L394.289 195.27L394.589 194.75L395.909 195.51C397.069 196.18 397.979 197.76 397.979 199.1V200.63C397.979 201.74 398.789 203.14 399.749 203.7L406.079 207.35L405.779 207.87Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M408.238 206.45L401.908 202.8C400.748 202.13 399.838 200.55 399.838 199.21V197.68C399.838 196.57 399.028 195.17 398.068 194.61L396.748 193.85L397.048 193.33L398.368 194.09C399.528 194.76 400.438 196.34 400.438 197.68V199.21C400.438 200.32 401.248 201.72 402.208 202.28L408.538 205.93L408.238 206.45Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M410.699 205.03L404.369 201.38C403.209 200.71 402.299 199.13 402.299 197.79V196.26C402.299 195.15 401.489 193.75 400.529 193.19L399.209 192.43L399.509 191.91L400.829 192.67C401.989 193.34 402.899 194.92 402.899 196.26V197.79C402.899 198.9 403.709 200.3 404.669 200.86L410.999 204.51L410.699 205.03Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M413.16 203.61L406.83 199.96C405.67 199.29 404.76 197.71 404.76 196.37V194.84C404.76 193.73 403.95 192.33 402.99 191.77L401.67 191.01L401.97 190.49L403.29 191.25C404.45 191.92 405.36 193.5 405.36 194.84V196.37C405.36 197.48 406.17 198.88 407.13 199.44L413.46 203.09L413.16 203.61Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M415.619 202.19L409.289 198.54C408.129 197.87 407.219 196.29 407.219 194.95V193.42C407.219 192.31 406.409 190.91 405.449 190.35L404.129 189.59L404.429 189.07L405.749 189.83C406.909 190.5 407.819 192.08 407.819 193.42V194.95C407.819 196.08 408.609 197.45 409.589 198.02L415.919 201.67L415.619 202.19Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M418.08 200.78L411.75 197.13C410.59 196.46 409.68 194.88 409.68 193.54V192.01C409.68 190.88 408.89 189.51 407.91 188.94L406.59 188.18L406.89 187.66L408.21 188.42C409.37 189.09 410.28 190.67 410.28 192.01V193.54C410.28 194.65 411.09 196.05 412.05 196.61L418.38 200.26L418.08 200.78Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M420.539 199.36L414.209 195.71C413.049 195.04 412.139 193.46 412.139 192.12V190.59C412.139 189.48 411.329 188.08 410.369 187.52L409.049 186.76L409.349 186.24L410.669 187C411.829 187.67 412.739 189.25 412.739 190.59V192.12C412.739 193.23 413.549 194.63 414.509 195.19L420.839 198.84L420.539 199.36Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M423 197.94L416.67 194.29C415.51 193.62 414.6 192.04 414.6 190.7V189.17C414.6 188.04 413.81 186.67 412.83 186.1L411.51 185.34L411.81 184.82L413.13 185.58C414.29 186.25 415.2 187.83 415.2 189.17V190.7C415.2 191.81 416.01 193.21 416.97 193.77L423.3 197.42L423 197.94Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M425.459 196.52L419.129 192.87C417.969 192.2 417.059 190.62 417.059 189.28V187.75C417.059 186.64 416.249 185.24 415.289 184.68L413.969 183.92L414.269 183.4L415.589 184.16C416.749 184.83 417.659 186.41 417.659 187.75V189.28C417.659 190.39 418.469 191.79 419.429 192.35L425.759 196L425.459 196.52Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M354.419 223.47L354.119 222.95L360.449 219.3C361.409 218.75 362.219 217.34 362.219 216.23V214.7C362.219 213.36 363.129 211.78 364.289 211.11L365.609 210.35L365.909 210.87L364.589 211.63C363.629 212.18 362.819 213.59 362.819 214.7V216.23C362.819 217.57 361.909 219.15 360.749 219.82L354.419 223.47Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M351.96 222.05L351.66 221.53L357.99 217.88C358.95 217.33 359.76 215.92 359.76 214.81V213.28C359.76 211.94 360.67 210.36 361.83 209.69L363.15 208.93L363.45 209.45L362.13 210.21C361.15 210.77 360.36 212.15 360.36 213.28V214.81C360.36 216.15 359.45 217.73 358.29 218.4L351.96 222.05Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M349.499 220.63L349.199 220.11L355.529 216.46C356.489 215.9 357.299 214.5 357.299 213.39V211.86C357.299 210.52 358.209 208.94 359.369 208.27L360.689 207.51L360.989 208.03L359.669 208.79C358.709 209.34 357.899 210.75 357.899 211.86V213.39C357.899 214.73 356.989 216.31 355.829 216.98L349.499 220.63Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M347.029 219.21L346.729 218.69L353.059 215.04C354.019 214.49 354.828 213.08 354.828 211.97V210.44C354.828 209.1 355.738 207.52 356.898 206.85L358.219 206.09L358.518 206.61L357.199 207.37C356.239 207.93 355.428 209.33 355.428 210.44V211.97C355.428 213.31 354.518 214.89 353.358 215.56L347.029 219.21Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M344.57 217.79L344.27 217.27L350.6 213.62C351.58 213.06 352.37 211.68 352.37 210.55V209.02C352.37 207.68 353.28 206.1 354.44 205.43L355.76 204.67L356.06 205.19L354.74 205.95C353.78 206.5 352.97 207.91 352.97 209.02V210.55C352.97 211.89 352.06 213.47 350.9 214.14L344.57 217.79Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M342.109 216.38L341.809 215.86L348.139 212.21C349.099 211.66 349.909 210.25 349.909 209.14V207.61C349.909 206.27 350.819 204.69 351.979 204.02L353.299 203.26L353.599 203.78L352.279 204.54C351.319 205.09 350.509 206.5 350.509 207.61V209.14C350.509 210.48 349.599 212.06 348.439 212.73L342.109 216.38Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M339.65 214.96L339.35 214.44L345.68 210.79C346.64 210.24 347.45 208.83 347.45 207.72V206.19C347.45 204.85 348.36 203.27 349.52 202.6L350.84 201.84L351.14 202.36L349.82 203.12C348.84 203.68 348.05 205.06 348.05 206.19V207.72C348.05 209.06 347.14 210.64 345.98 211.31L339.65 214.96Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M337.189 213.54L336.889 213.02L343.219 209.37C344.179 208.82 344.989 207.41 344.989 206.3V204.77C344.989 203.43 345.899 201.85 347.059 201.18L348.379 200.42L348.679 200.94L347.359 201.7C346.379 202.26 345.589 203.64 345.589 204.77V206.3C345.589 207.64 344.679 209.22 343.519 209.89L337.189 213.54Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M334.73 212.12L334.43 211.6L340.76 207.95C341.72 207.4 342.53 205.99 342.53 204.88V203.35C342.53 202.01 343.44 200.43 344.6 199.76L345.92 199L346.22 199.52L344.9 200.28C343.94 200.83 343.13 202.24 343.13 203.35V204.88C343.13 206.22 342.22 207.8 341.06 208.47L334.73 212.12Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M332.269 210.7L331.969 210.18L338.299 206.53C339.279 205.97 340.069 204.59 340.069 203.46V201.93C340.069 200.59 340.979 199.01 342.139 198.34L343.459 197.58L343.759 198.1L342.439 198.86C341.479 199.41 340.669 200.82 340.669 201.93V203.46C340.669 204.8 339.759 206.38 338.599 207.05L332.269 210.7Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M329.81 209.28L329.51 208.76L335.84 205.11C336.8 204.56 337.61 203.15 337.61 202.04V200.51C337.61 199.17 338.52 197.59 339.68 196.92L341 196.16L341.3 196.68L339.98 197.44C339.02 197.99 338.21 199.4 338.21 200.51V202.04C338.21 203.38 337.3 204.96 336.14 205.63L329.81 209.28Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M327.349 207.87L327.049 207.35L333.379 203.7C334.339 203.15 335.149 201.74 335.149 200.63V199.1C335.149 197.76 336.059 196.18 337.219 195.51L338.539 194.75L338.839 195.27L337.519 196.03C336.539 196.59 335.749 197.97 335.749 199.1V200.63C335.749 201.97 334.839 203.55 333.679 204.22L327.349 207.87Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M324.89 206.45L324.59 205.93L330.92 202.28C331.88 201.73 332.69 200.32 332.69 199.21V197.68C332.69 196.34 333.6 194.76 334.76 194.09L336.08 193.33L336.38 193.85L335.06 194.61C334.1 195.16 333.29 196.57 333.29 197.68V199.21C333.29 200.55 332.38 202.13 331.22 202.8L324.89 206.45Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M322.419 205.03L322.119 204.51L328.449 200.86C329.409 200.31 330.219 198.9 330.219 197.79V196.26C330.219 194.92 331.129 193.34 332.289 192.67L333.609 191.91L333.909 192.43L332.589 193.19C331.629 193.74 330.819 195.15 330.819 196.26V197.79C330.819 199.13 329.909 200.71 328.749 201.38L322.419 205.03Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M319.96 203.61L319.66 203.09L325.99 199.44C326.97 198.88 327.76 197.5 327.76 196.37V194.84C327.76 193.5 328.67 191.92 329.83 191.25L331.15 190.49L331.45 191.01L330.13 191.77C329.17 192.32 328.36 193.73 328.36 194.84V196.37C328.36 197.71 327.45 199.29 326.29 199.96L319.96 203.61Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M317.499 202.19L317.199 201.67L323.529 198.02C324.509 197.46 325.299 196.08 325.299 194.95V193.42C325.299 192.08 326.209 190.5 327.369 189.83L328.689 189.07L328.989 189.59L327.669 190.35C326.709 190.9 325.899 192.31 325.899 193.42V194.95C325.899 196.29 324.989 197.87 323.829 198.54L317.499 202.19Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M315.04 200.78L314.74 200.26L321.07 196.61C322.03 196.06 322.84 194.65 322.84 193.54V192.01C322.84 190.67 323.75 189.09 324.91 188.42L326.23 187.66L326.53 188.18L325.21 188.94C324.23 189.5 323.44 190.88 323.44 192.01V193.54C323.44 194.88 322.53 196.46 321.37 197.13L315.04 200.78Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M312.579 199.36L312.279 198.84L318.609 195.19C319.569 194.64 320.379 193.23 320.379 192.12V190.59C320.379 189.25 321.289 187.67 322.449 187L323.769 186.24L324.069 186.76L322.749 187.52C321.789 188.07 320.979 189.48 320.979 190.59V192.12C320.979 193.46 320.069 195.04 318.909 195.71L312.579 199.36Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M310.12 197.94L309.82 197.42L316.15 193.77C317.11 193.21 317.92 191.81 317.92 190.7V189.17C317.92 187.83 318.83 186.25 319.99 185.58L321.31 184.82L321.61 185.34L320.29 186.1C319.33 186.65 318.52 188.06 318.52 189.17V190.7C318.52 192.04 317.61 193.62 316.45 194.29L310.12 197.94Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M307.659 196.52L307.359 196L313.689 192.35C314.649 191.79 315.459 190.39 315.459 189.28V187.75C315.459 186.41 316.369 184.83 317.529 184.16L318.849 183.4L319.149 183.92L317.829 184.68C316.869 185.23 316.059 186.64 316.059 187.75V189.28C316.059 190.62 315.149 192.2 313.989 192.87L307.659 196.52Z"
        fill="#42E8E0"
      />
      <motion.path
        opacity="0.5"
        d="M366.65 199.15L329.25 177.56V160.79L366.46 181.45L404.04 159.91V177.56L366.65 199.15Z"
        fill="url(#paint8_linear)"
      />
      <motion.path
        opacity="0.7"
        d="M404.04 164.14V159.91L366.46 181.44L329.25 160.79V164.3L364.22 184.49C365.46 185.21 367.63 185.12 369.06 184.29L404.04 164.14Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M369.06 181.75L405.16 160.96C405.95 160.5 406.35 159.92 406.34 159.36V152.85L405.1 153.95L404.04 153.33C404.16 153.19 404.24 153.08 404.21 153.04C404.15 152.93 403.68 152.12 403.68 152.12L378.21 137.09L370.11 132.38L366.95 131.47L329.89 152.76L330 153.62L329.01 154.19L327.43 153.48L327.42 159.96C327.41 160.42 327.68 160.87 328.25 161.2L364.2 181.95C365.46 182.66 367.63 182.57 369.06 181.75ZM383.72 165.69L367.37 174.62L365.96 174.55L338.66 159.28L331.1 155.37L366.72 134.85C367.05 134.66 367.54 134.64 367.83 134.81L402.5 154.82L383.72 165.69Z"
        fill="url(#paint9_linear)"
      />
      <motion.path
        d="M369.06 181.75L405.16 160.96C405.95 160.5 406.35 159.92 406.34 159.36V152.85L405.1 153.95L404.04 153.33C404.16 153.19 404.24 153.08 404.21 153.04C404.15 152.93 403.68 152.12 403.68 152.12L378.21 137.09L370.11 132.38L366.95 131.47L329.89 152.76L330 153.62L329.01 154.19L327.43 153.48L327.42 159.96C327.41 160.42 327.68 160.87 328.25 161.2L364.2 181.95C365.46 182.66 367.63 182.57 369.06 181.75ZM383.72 165.69L367.37 174.62L365.96 174.55L338.66 159.28L331.1 155.37L366.72 134.85C367.05 134.66 367.54 134.64 367.83 134.81L402.5 154.82L383.72 165.69Z"
        fill="url(#paint10_linear)"
      />
      <motion.path
        opacity="0.6"
        d="M366.461 182.43V174.57L367.371 174.61L383.721 165.68L402.501 154.8L401.881 154.44C402.461 154.11 405.751 153.19 406.361 152.84V158.88V159.35C406.371 159.9 405.971 160.49 405.181 160.95L369.081 181.74C368.321 182.18 367.371 182.41 366.461 182.43Z"
        fill="#0F054C"
      />
      <motion.path
        d="M369.071 175.16L405.171 154.37C406.601 153.54 406.761 152.29 405.521 151.58L369.571 130.83C368.331 130.11 366.161 130.2 364.731 131.03L328.631 151.82C327.201 152.65 327.041 153.9 328.281 154.61L364.231 175.36C365.471 176.07 367.631 175.98 369.071 175.16ZM367.071 173.94C366.741 174.13 366.251 174.15 365.961 173.98L330.651 153.6C330.371 153.44 330.401 153.15 330.731 152.96L366.731 132.23C367.061 132.04 367.551 132.02 367.841 132.19L403.151 152.57C403.431 152.73 403.401 153.02 403.071 153.21L367.071 173.94Z"
        fill="url(#paint11_linear)"
      />
      <motion.path
        d="M369.071 175.16L405.171 154.37C406.601 153.54 406.761 152.29 405.521 151.58L369.571 130.83C368.331 130.11 366.161 130.2 364.731 131.03L328.631 151.82C327.201 152.65 327.041 153.9 328.281 154.61L364.231 175.36C365.471 176.07 367.631 175.98 369.071 175.16ZM367.071 173.94C366.741 174.13 366.251 174.15 365.961 173.98L330.651 153.6C330.371 153.44 330.401 153.15 330.731 152.96L366.731 132.23C367.061 132.04 367.551 132.02 367.841 132.19L403.151 152.57C403.431 152.73 403.401 153.02 403.071 153.21L367.071 173.94Z"
        fill="url(#paint12_linear)"
      />
      <motion.path
        d="M403.139 152.22L367.829 131.84C367.549 131.68 367.049 131.7 366.719 131.88L330.719 152.61C330.459 152.76 330.379 152.98 330.519 153.14C330.569 153.08 330.629 153.02 330.719 152.96L366.719 132.23C367.049 132.04 367.539 132.02 367.829 132.19L403.139 152.57C403.189 152.6 403.229 152.64 403.259 152.68C403.379 152.51 403.349 152.33 403.139 152.22Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M405.169 154.01L369.069 174.8C367.639 175.63 365.469 175.71 364.229 175L328.279 154.25C327.789 153.97 327.519 153.6 327.459 153.21C327.379 153.73 327.639 154.24 328.279 154.6L364.229 175.35C365.469 176.07 367.639 175.98 369.069 175.15L405.169 154.36C406.039 153.86 406.429 153.21 406.339 152.61C406.259 153.11 405.859 153.61 405.169 154.01Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M367.83 134.8C367.55 134.64 367.05 134.66 366.72 134.84L332.39 154.61L330.64 153.6C330.36 153.44 330.39 153.15 330.72 152.96L366.72 132.23C367.05 132.04 367.54 132.02 367.83 132.19L403.14 152.57C403.42 152.73 403.39 153.02 403.06 153.21L401.39 154.17L367.83 134.8Z"
        fill="#251C72"
      />
      <motion.path
        opacity="0.7"
        d="M403.06 153.21C403.39 153.02 403.42 152.74 403.14 152.57L367.83 132.19C367.55 132.03 367.05 132.05 366.72 132.23L330.72 152.96C330.39 153.15 330.36 153.43 330.64 153.6L365.95 173.98C366.23 174.14 366.73 174.12 367.06 173.94L403.06 153.21Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M369.06 170.49L405.16 149.7C405.95 149.24 406.35 148.66 406.34 148.1V141.59L405.1 142.69L404.04 142.07C404.16 141.93 404.24 141.82 404.21 141.78C404.15 141.67 403.68 140.86 403.68 140.86L378.21 125.83L370.11 121.12L366.95 120.21L329.89 141.5L330 142.36L329.01 142.93L327.43 142.22L327.42 148.7C327.41 149.16 327.68 149.61 328.25 149.94L364.2 170.69C365.46 171.41 367.63 171.32 369.06 170.49ZM383.72 154.43L367.37 163.36L365.96 163.29L338.66 148.02L331.1 144.11L366.72 123.59C367.05 123.4 367.54 123.38 367.83 123.55L402.5 143.56L383.72 154.43Z"
        fill="url(#paint13_linear)"
      />
      <motion.path
        d="M369.06 170.49L405.16 149.7C405.95 149.24 406.35 148.66 406.34 148.1V141.59L405.1 142.69L404.04 142.07C404.16 141.93 404.24 141.82 404.21 141.78C404.15 141.67 403.68 140.86 403.68 140.86L378.21 125.83L370.11 121.12L366.95 120.21L329.89 141.5L330 142.36L329.01 142.93L327.43 142.22L327.42 148.7C327.41 149.16 327.68 149.61 328.25 149.94L364.2 170.69C365.46 171.41 367.63 171.32 369.06 170.49ZM383.72 154.43L367.37 163.36L365.96 163.29L338.66 148.02L331.1 144.11L366.72 123.59C367.05 123.4 367.54 123.38 367.83 123.55L402.5 143.56L383.72 154.43Z"
        fill="url(#paint14_linear)"
      />
      <motion.path
        opacity="0.6"
        d="M366.461 171.17V163.31L367.371 163.35L383.721 154.42L402.501 143.54L401.881 143.18C402.461 142.85 405.751 141.93 406.361 141.58V147.62V148.09C406.371 148.64 405.971 149.23 405.181 149.69L369.081 170.48C368.321 170.92 367.371 171.15 366.461 171.17Z"
        fill="#0F054C"
      />
      <motion.path
        d="M369.071 163.9L405.171 143.11C406.601 142.28 406.761 141.03 405.521 140.32L369.571 119.57C368.331 118.85 366.161 118.94 364.731 119.77L328.631 140.56C327.201 141.39 327.041 142.64 328.281 143.35L364.231 164.1C365.471 164.81 367.631 164.73 369.071 163.9ZM367.071 162.69C366.741 162.88 366.251 162.9 365.961 162.73L330.651 142.35C330.371 142.19 330.401 141.9 330.731 141.71L366.731 120.98C367.061 120.79 367.551 120.77 367.841 120.94L403.151 141.32C403.431 141.48 403.401 141.77 403.071 141.96L367.071 162.69Z"
        fill="url(#paint15_linear)"
      />
      <motion.path
        d="M369.071 163.9L405.171 143.11C406.601 142.28 406.761 141.03 405.521 140.32L369.571 119.57C368.331 118.85 366.161 118.94 364.731 119.77L328.631 140.56C327.201 141.39 327.041 142.64 328.281 143.35L364.231 164.1C365.471 164.81 367.631 164.73 369.071 163.9ZM367.071 162.69C366.741 162.88 366.251 162.9 365.961 162.73L330.651 142.35C330.371 142.19 330.401 141.9 330.731 141.71L366.731 120.98C367.061 120.79 367.551 120.77 367.841 120.94L403.151 141.32C403.431 141.48 403.401 141.77 403.071 141.96L367.071 162.69Z"
        fill="url(#paint16_linear)"
      />
      <motion.path
        d="M403.139 140.96L367.829 120.58C367.549 120.42 367.049 120.44 366.719 120.62L330.719 141.35C330.459 141.5 330.379 141.72 330.519 141.88C330.569 141.82 330.629 141.76 330.719 141.7L366.719 120.97C367.049 120.78 367.539 120.76 367.829 120.93L403.139 141.31C403.189 141.34 403.229 141.38 403.259 141.42C403.379 141.26 403.349 141.08 403.139 140.96Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M405.169 142.76L369.069 163.55C367.639 164.38 365.469 164.46 364.229 163.75L328.279 143C327.789 142.72 327.519 142.35 327.459 141.96C327.379 142.48 327.639 142.99 328.279 143.35L364.229 164.1C365.469 164.82 367.639 164.73 369.069 163.9L405.169 143.11C406.039 142.61 406.429 141.96 406.339 141.36C406.259 141.85 405.859 142.35 405.169 142.76Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M367.83 123.55C367.55 123.39 367.05 123.41 366.72 123.59L332.39 143.36L330.64 142.35C330.36 142.19 330.39 141.9 330.72 141.71L366.72 120.98C367.05 120.79 367.54 120.77 367.83 120.94L403.14 141.32C403.42 141.48 403.39 141.77 403.06 141.96L401.39 142.92L367.83 123.55Z"
        fill="#251C72"
      />
      <motion.path
        opacity="0.7"
        d="M403.06 141.95C403.39 141.76 403.42 141.48 403.14 141.31L367.83 120.93C367.55 120.77 367.05 120.79 366.72 120.97L330.72 141.7C330.39 141.89 330.36 142.17 330.64 142.34L365.95 162.72C366.23 162.88 366.73 162.86 367.06 162.68L403.06 141.95Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M369.06 159.24L405.16 138.45C405.95 137.99 406.35 137.41 406.34 136.85V130.34L405.1 131.44L404.04 130.82C404.16 130.68 404.24 130.57 404.21 130.53C404.15 130.42 403.68 129.61 403.68 129.61L378.21 114.58L370.11 109.87L366.95 108.96L329.89 130.25L330 131.11L329.01 131.68L327.43 130.97L327.42 137.45C327.41 137.91 327.68 138.36 328.25 138.69L364.2 159.44C365.46 160.15 367.63 160.06 369.06 159.24ZM383.72 143.18L367.37 152.11L365.96 152.04L338.66 136.77L331.1 132.86L366.72 112.34C367.05 112.15 367.54 112.13 367.83 112.3L402.5 132.31L383.72 143.18Z"
        fill="url(#paint17_linear)"
      />
      <motion.path
        d="M369.06 159.24L405.16 138.45C405.95 137.99 406.35 137.41 406.34 136.85V130.34L405.1 131.44L404.04 130.82C404.16 130.68 404.24 130.57 404.21 130.53C404.15 130.42 403.68 129.61 403.68 129.61L378.21 114.58L370.11 109.87L366.95 108.96L329.89 130.25L330 131.11L329.01 131.68L327.43 130.97L327.42 137.45C327.41 137.91 327.68 138.36 328.25 138.69L364.2 159.44C365.46 160.15 367.63 160.06 369.06 159.24ZM383.72 143.18L367.37 152.11L365.96 152.04L338.66 136.77L331.1 132.86L366.72 112.34C367.05 112.15 367.54 112.13 367.83 112.3L402.5 132.31L383.72 143.18Z"
        fill="url(#paint18_linear)"
      />
      <motion.path
        opacity="0.6"
        d="M366.461 159.91V152.05L367.371 152.09L383.721 143.16L402.501 132.28L401.881 131.92C402.461 131.59 405.751 130.67 406.361 130.32V136.36V136.83C406.371 137.38 405.971 137.97 405.181 138.43L369.081 159.22C368.321 159.67 367.371 159.89 366.461 159.91Z"
        fill="#0F054C"
      />
      <motion.path
        d="M369.071 152.65L405.171 131.86C406.601 131.03 406.761 129.78 405.521 129.07L369.571 108.32C368.331 107.6 366.161 107.69 364.731 108.52L328.631 129.31C327.201 130.14 327.041 131.39 328.281 132.1L364.231 152.85C365.471 153.56 367.631 153.47 369.071 152.65ZM367.071 151.43C366.741 151.62 366.251 151.64 365.961 151.47L330.651 131.09C330.371 130.93 330.401 130.64 330.731 130.45L366.731 109.72C367.061 109.53 367.551 109.51 367.841 109.68L403.151 130.06C403.431 130.22 403.401 130.51 403.071 130.7L367.071 151.43Z"
        fill="url(#paint19_linear)"
      />
      <motion.path
        d="M369.071 152.65L405.171 131.86C406.601 131.03 406.761 129.78 405.521 129.07L369.571 108.32C368.331 107.6 366.161 107.69 364.731 108.52L328.631 129.31C327.201 130.14 327.041 131.39 328.281 132.1L364.231 152.85C365.471 153.56 367.631 153.47 369.071 152.65ZM367.071 151.43C366.741 151.62 366.251 151.64 365.961 151.47L330.651 131.09C330.371 130.93 330.401 130.64 330.731 130.45L366.731 109.72C367.061 109.53 367.551 109.51 367.841 109.68L403.151 130.06C403.431 130.22 403.401 130.51 403.071 130.7L367.071 151.43Z"
        fill="url(#paint20_linear)"
      />
      <motion.path
        d="M403.139 129.7L367.829 109.32C367.549 109.16 367.049 109.18 366.719 109.36L330.719 130.09C330.459 130.24 330.379 130.46 330.519 130.62C330.569 130.56 330.629 130.5 330.719 130.44L366.719 109.71C367.049 109.52 367.539 109.5 367.829 109.67L403.139 130.05C403.189 130.08 403.229 130.12 403.259 130.16C403.379 130 403.349 129.82 403.139 129.7Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M405.169 131.5L369.069 152.29C367.639 153.12 365.469 153.2 364.229 152.49L328.279 131.74C327.789 131.46 327.519 131.09 327.459 130.7C327.379 131.22 327.639 131.73 328.279 132.09L364.229 152.84C365.469 153.56 367.639 153.47 369.069 152.64L405.169 131.85C406.039 131.35 406.429 130.7 406.339 130.1C406.259 130.59 405.859 131.1 405.169 131.5Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M367.83 112.29C367.55 112.13 367.05 112.15 366.72 112.33L332.39 132.1L330.64 131.09C330.36 130.93 330.39 130.64 330.72 130.45L366.72 109.72C367.05 109.53 367.54 109.51 367.83 109.68L403.14 130.06C403.42 130.22 403.39 130.51 403.06 130.7L401.39 131.66L367.83 112.29Z"
        fill="#251C72"
      />
      <motion.path
        opacity="0.7"
        d="M403.06 130.7C403.39 130.51 403.42 130.23 403.14 130.06L367.83 109.68C367.55 109.52 367.05 109.54 366.72 109.72L330.72 130.45C330.39 130.64 330.36 130.92 330.64 131.09L365.95 151.47C366.23 151.63 366.73 151.61 367.06 151.43L403.06 130.7Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M369.06 147.98L405.16 127.19C405.95 126.73 406.35 126.15 406.34 125.59V119.08L405.1 120.18L404.04 119.56C404.16 119.42 404.24 119.31 404.21 119.27C404.15 119.16 403.68 118.35 403.68 118.35L378.21 103.32L370.11 98.6101L366.95 97.7001L329.89 118.99L330 119.85L329.01 120.42L327.43 119.71L327.42 126.19C327.41 126.65 327.68 127.1 328.25 127.43L364.2 148.18C365.46 148.89 367.63 148.8 369.06 147.98ZM383.72 131.92L367.37 140.85L365.96 140.78L338.66 125.51L331.1 121.6L366.72 101.08C367.05 100.89 367.54 100.87 367.83 101.04L402.5 121.05L383.72 131.92Z"
        fill="url(#paint21_linear)"
      />
      <motion.path
        d="M369.06 147.98L405.16 127.19C405.95 126.73 406.35 126.15 406.34 125.59V119.08L405.1 120.18L404.04 119.56C404.16 119.42 404.24 119.31 404.21 119.27C404.15 119.16 403.68 118.35 403.68 118.35L378.21 103.32L370.11 98.6101L366.95 97.7001L329.89 118.99L330 119.85L329.01 120.42L327.43 119.71L327.42 126.19C327.41 126.65 327.68 127.1 328.25 127.43L364.2 148.18C365.46 148.89 367.63 148.8 369.06 147.98ZM383.72 131.92L367.37 140.85L365.96 140.78L338.66 125.51L331.1 121.6L366.72 101.08C367.05 100.89 367.54 100.87 367.83 101.04L402.5 121.05L383.72 131.92Z"
        fill="url(#paint22_linear)"
      />
      <motion.path
        opacity="0.6"
        d="M366.461 148.66V140.8L367.371 140.84L383.721 131.91L402.501 121.03L401.881 120.67C402.461 120.34 405.751 119.42 406.361 119.07V125.11V125.58C406.371 126.13 405.971 126.72 405.181 127.18L369.081 147.97C368.321 148.41 367.371 148.64 366.461 148.66Z"
        fill="#0F054C"
      />
      <motion.path
        d="M369.071 141.39L405.171 120.6C406.601 119.77 406.761 118.52 405.521 117.81L369.571 97.0601C368.331 96.3401 366.161 96.4301 364.731 97.2601L328.631 118.05C327.201 118.88 327.041 120.13 328.281 120.84L364.231 141.59C365.471 142.3 367.631 142.21 369.071 141.39ZM367.071 140.17C366.741 140.36 366.251 140.38 365.961 140.21L330.651 119.83C330.371 119.67 330.401 119.38 330.731 119.19L366.731 98.4601C367.061 98.2701 367.551 98.2501 367.841 98.4201L403.151 118.8C403.431 118.96 403.401 119.25 403.071 119.44L367.071 140.17Z"
        fill="url(#paint23_linear)"
      />
      <motion.path
        d="M369.071 141.39L405.171 120.6C406.601 119.77 406.761 118.52 405.521 117.81L369.571 97.0601C368.331 96.3401 366.161 96.4301 364.731 97.2601L328.631 118.05C327.201 118.88 327.041 120.13 328.281 120.84L364.231 141.59C365.471 142.3 367.631 142.21 369.071 141.39ZM367.071 140.17C366.741 140.36 366.251 140.38 365.961 140.21L330.651 119.83C330.371 119.67 330.401 119.38 330.731 119.19L366.731 98.4601C367.061 98.2701 367.551 98.2501 367.841 98.4201L403.151 118.8C403.431 118.96 403.401 119.25 403.071 119.44L367.071 140.17Z"
        fill="url(#paint24_linear)"
      />
      <motion.path
        d="M403.139 118.45L367.829 98.07C367.549 97.91 367.049 97.93 366.719 98.11L330.719 118.84C330.459 118.99 330.379 119.21 330.519 119.37C330.569 119.31 330.629 119.25 330.719 119.19L366.719 98.46C367.049 98.27 367.539 98.25 367.829 98.42L403.139 118.8C403.189 118.83 403.229 118.87 403.259 118.91C403.379 118.75 403.349 118.57 403.139 118.45Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M405.169 120.24L369.069 141.03C367.639 141.86 365.469 141.94 364.229 141.23L328.279 120.48C327.789 120.2 327.519 119.83 327.459 119.44C327.379 119.96 327.639 120.47 328.279 120.83L364.229 141.58C365.469 142.3 367.639 142.21 369.069 141.38L405.169 120.59C406.039 120.09 406.429 119.44 406.339 118.84C406.259 119.34 405.859 119.84 405.169 120.24Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M367.83 101.03C367.55 100.87 367.05 100.89 366.72 101.07L332.39 120.84L330.64 119.83C330.36 119.67 330.39 119.38 330.72 119.19L366.72 98.46C367.05 98.27 367.54 98.25 367.83 98.42L403.14 118.8C403.42 118.96 403.39 119.25 403.06 119.44L401.39 120.4L367.83 101.03Z"
        fill="#251C72"
      />
      <motion.path
        opacity="0.7"
        d="M403.06 119.44C403.39 119.25 403.42 118.97 403.14 118.8L367.83 98.42C367.55 98.26 367.05 98.28 366.72 98.46L330.72 119.19C330.39 119.38 330.36 119.66 330.64 119.83L365.95 140.21C366.23 140.37 366.73 140.35 367.06 140.17L403.06 119.44Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M398.43 127.95C398.43 128.33 398.17 128.78 397.84 128.97C397.51 129.16 397.25 129.01 397.25 128.63C397.25 128.25 397.51 127.8 397.84 127.61C398.16 127.42 398.43 127.57 398.43 127.95Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M400.481 126.76C400.481 127.14 400.221 127.59 399.891 127.78C399.571 127.97 399.301 127.82 399.301 127.44C399.301 127.06 399.561 126.61 399.891 126.42C400.211 126.23 400.481 126.39 400.481 126.76Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M402.53 125.58C402.53 125.96 402.27 126.41 401.94 126.6C401.61 126.79 401.35 126.64 401.35 126.26C401.35 125.88 401.61 125.43 401.94 125.24C402.27 125.05 402.53 125.2 402.53 125.58Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M398.43 139.44C398.43 139.82 398.17 140.27 397.84 140.46C397.51 140.65 397.25 140.5 397.25 140.12C397.25 139.74 397.51 139.29 397.84 139.1C398.16 138.91 398.43 139.06 398.43 139.44Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M400.481 138.25C400.481 138.63 400.221 139.08 399.891 139.27C399.571 139.46 399.301 139.31 399.301 138.93C399.301 138.55 399.561 138.1 399.891 137.91C400.211 137.73 400.481 137.88 400.481 138.25Z"
        fill="#42E8E0"
      />
      <motion.path
        d="M402.53 137.07C402.53 137.45 402.27 137.9 401.94 138.09C401.61 138.28 401.35 138.13 401.35 137.75C401.35 137.37 401.61 136.92 401.94 136.73C402.27 136.54 402.53 136.69 402.53 137.07Z"
        fill="#42E8E0"
      />
      <path
        d="M398.43 161.61C398.43 161.99 398.17 162.44 397.84 162.63C397.51 162.82 397.25 162.67 397.25 162.29C397.25 161.91 397.51 161.46 397.84 161.27C398.16 161.08 398.43 161.24 398.43 161.61Z"
        fill="#706EC4"
      />
      <path
        d="M400.481 160.43C400.481 160.81 400.221 161.26 399.891 161.45C399.571 161.64 399.301 161.49 399.301 161.11C399.301 160.73 399.561 160.28 399.891 160.09C400.211 159.9 400.481 160.05 400.481 160.43Z"
        fill="#706EC4"
      />
      <path
        d="M402.53 159.24C402.53 159.62 402.27 160.07 401.94 160.26C401.61 160.45 401.35 160.3 401.35 159.92C401.35 159.54 401.61 159.09 401.94 158.9C402.27 158.72 402.53 158.87 402.53 159.24Z"
        fill="#706EC4"
      />
    </motion.g>
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(366.885 184.502) scale(45.5152)"
      >
        <stop stopColor="#03002D" />
        <stop offset="0.1597" stopColor="#413F61" />
        <stop offset="0.3263" stopColor="#7A7890" />
        <stop offset="0.4878" stopColor="#AAA9B8" />
        <stop offset="0.6398" stopColor="#CFCED7" />
        <stop offset="0.78" stopColor="#E9E9ED" />
        <stop offset="0.9044" stopColor="#F9F9FA" />
        <stop offset="1" stopColor="white" />
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="293.985"
        y1="199.842"
        x2="418.594"
        y2="199.842"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="329.251"
        y1="183.004"
        x2="404.044"
        y2="183.004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="366.647"
        y1="169.486"
        x2="404.044"
        y2="169.486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="329.251"
        y1="169.486"
        x2="366.648"
        y2="169.486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="329.251"
        y1="177.559"
        x2="404.044"
        y2="177.559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="366.648"
        y1="191.077"
        x2="404.044"
        y2="191.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="329.251"
        y1="191.077"
        x2="366.648"
        y2="191.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="329.251"
        y1="179.532"
        x2="404.044"
        y2="179.532"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1507" stopColor="#43E3E0" />
        <stop offset="0.3285" stopColor="#45D5DF" />
        <stop offset="0.5199" stopColor="#49BDDD" />
        <stop offset="0.7209" stopColor="#4E9CDB" />
        <stop offset="0.9274" stopColor="#5472D8" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="360.181"
        y1="144.909"
        x2="378.504"
        y2="176.718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="360.181"
        y1="144.909"
        x2="378.504"
        y2="176.718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="356.812"
        y1="135.578"
        x2="376.984"
        y2="170.599"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="354.026"
        y1="130.741"
        x2="390.42"
        y2="193.925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="360.181"
        y1="133.652"
        x2="378.504"
        y2="165.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="360.181"
        y1="133.652"
        x2="378.504"
        y2="165.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="356.812"
        y1="124.322"
        x2="376.985"
        y2="159.343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="354.026"
        y1="119.485"
        x2="390.42"
        y2="182.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="360.181"
        y1="122.396"
        x2="378.504"
        y2="154.206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="360.181"
        y1="122.396"
        x2="378.504"
        y2="154.206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="356.812"
        y1="113.065"
        x2="376.984"
        y2="148.086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="354.026"
        y1="108.228"
        x2="390.42"
        y2="171.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="360.182"
        y1="111.139"
        x2="378.504"
        y2="142.949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="360.182"
        y1="111.139"
        x2="378.504"
        y2="142.949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="356.812"
        y1="101.809"
        x2="376.984"
        y2="136.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCBEFF" />
        <stop offset="0.1659" stopColor="#B6B8FC" />
        <stop offset="0.3956" stopColor="#A4A8F5" />
        <stop offset="0.6625" stopColor="#868DEA" />
        <stop offset="0.9554" stopColor="#5E67DA" />
        <stop offset="1" stopColor="#5761D7" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="354.026"
        y1="96.9719"
        x2="390.42"
        y2="160.156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706EC4" />
        <stop offset="0.0222" stopColor="#6D6BC2" />
        <stop offset="0.2509" stopColor="#5651AC" />
        <stop offset="0.4865" stopColor="#463F9D" />
        <stop offset="0.7314" stopColor="#3C3494" />
        <stop offset="1" stopColor="#393091" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="675.29" height="373.17" fill="white" />
      </clipPath>
    </defs>
  </SvgStyles>
)

const FourWrapperStyles = styled(WrapperStyles)`
  @media only screen and (max-width: 1418px) {
    margin: 220px 30px 220px 122px;
    > div > span {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 1208px) {
    > div > span {
      font-size: 32px;
    }
  }
  @media only screen and (max-width: 1182px) {
    margin: 200px 0px 180px 60px;
  }
  @media only screen and (max-width: 740px) {
    margin: 180px 30px 64px;
    flex-direction: column;
    > div > span {
      font-size: 36px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
    }
  }
`

const FourOFourPage = () => {
  return (
    <>
      <SEO />
      <Container>
        <FourWrapperStyles margin="246px 30px 246px 122px">
          <Flex flex="3" direction="column">
            <Text
              fontSize="10px"
              lineHeight="normal"
              fontWeight="normal"
              textTransform="uppercase"
              color={"var(--headers-color)"}
              letterSpacing="1px"
            >
              O nie!
            </Text>
            <Text
              as="span"
              fontSize="48px"
              fontWeight="600"
              lineHeight="normal"
              color="var(--headers-color)"
              margin="12px 0 0"
            >
              404.
              <br />
              Podana strona nie istnieje
            </Text>
            <Text
              fontSize="16px"
              margin="20px 0 0"
              fontWeight="normal"
              lineHeight="normal"
              color="var(--body-text)"
            >
              Twój internet działa jak należy. Najprawdopodobniej strona została
              przeniesiona pod inny adres. Sprawdź czy adres strony nie zawiera
              literówki.
            </Text>
            <ButtonLink
              to="/"
              maxwidth="244px"
              margin="45px 0 0"
              whileTap={{ scale: 0.98 }}
              whileHover={{}}
            >
              Strona Główna
            </ButtonLink>
          </Flex>
          <Flex flex="3" justifyContent="center">
            <Icon />
          </Flex>
        </FourWrapperStyles>
      </Container>
    </>
  )
}

export default FourOFourPage
